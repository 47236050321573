<template>
  <div class="noscrollmain">
    <common-top
      title="擂台排行"
      :isShowSearch="false"
      @search="showModal = true"
      :isFilter="true"
      :sortList="sortList"
      @sortData="sortData"
    ></common-top>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div>
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>

      <div style="display: flex; justify-content: space-between">
        <div>
          <leitai-activity-com
            @chooseActivity="chooseActivity"
          ></leitai-activity-com>
        </div>

        <div>
          <div class="listRankTitle">
            <div
              v-for="(item, key) in rankCycle"
              :key="key"
              @click="chooseRankType(item)"
              :class="item == defaultRankCycle ? 'banganStyAcvive' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <dept-tab-com @chooseTab="chooseTabItem"></dept-tab-com>

      <list-rank
        :needRankTitle="false"
        :columns="columns"
        :rankCycle="rankCycle"
        :defaultRankCycle="defaultRankCycle"
        :showList="showList"
        @chooseRankType="chooseRankType"
        :showLoad="showLoad"
      ></list-rank>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import { wxConPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listRank from "../../components/listRank.vue";
import descCom from "../../components/descComAndSort.vue";
import leitaiActivityCom from "../../components/leitaiActivityCom.vue";
import deptTabCom from "../../components/deptTabCom.vue";

export default {
  name: "jifenRank",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listRank,
    leitaiActivityCom,
    descCom,
    deptTabCom,
  },

  data() {
    return {
      curTabItem: {},

      activityId: "",

      isShowSearch: false, // 是否显示搜索框

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级

      showDetailStatus: false,
      detailInfo: {}, // 单位详情

      showList: [],
      deptData: [],
      dataAllList: [],
      dataList: [],
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "",
      endDate: "",
      rankCycle: ["周榜", "月榜"],
      defaultRankCycle: "周榜",
      columns: [
        {
          name: "单位",
          value: "deptName",
        },
        {
          name: "总得分",
          value: "score",
        },

        {
          name: "正确率排名",
          value: "正确率排名",
        },
        // {
        //   name: "正确率",
        //   value: "正确率",
        // },
        {
          name: "答题次数",
          value: "count",
        },
        // {
        //   name: "答题时长",
        //   value: "timeMs",
        // },
      ],

      sortList: [
        // 默认降序 desc  可选升序 asc
        { name: "得分", value: "score", order: "desc" },
        { name: "正确率排名", value: "正确率排名", order: "aes" },
        { name: "答题次数", value: "count", order: "desc" },
      ],

      // count: 1
      // deptId: 3386
      // deptName: "肇庆车务段"
      // id: "161706463911809745ff2"
      // paperScore: 10
      // score: 6
      // timeMs: 50631
    };
  },

  methods: {
    chooseTabItem(tabItem) {
      this.curTabItem = tabItem;
      // 查找部门为 childDeptList
      this.initRankGroup(tabItem);
    },
    initRankGroup(tabItem) {
      // console.log("initRankGroup------", tabItem);
      if (!_.isEmpty(tabItem) && tabItem) {
        const dataList = [];
        for (const iterator of this.dataList) {
          if (_.indexOf(tabItem.childDeptList, iterator.deptId) > -1) {
            dataList.push(iterator);
          }
        }
        this.showList = dataList;
      } else {
        this.showList = this.dataList;
      }
    },

    async chooseActivity(activityId) {
      this.activityId = activityId;
      if (this.dataAllList.length > 0) {
        this.dealRankData();
      } else {
        await this.getDeptStatus();
        this.dealRankData();
      }
    },
    getRankParam(type) {
      switch (type) {
        case "周榜":
          return "weekRing";
        case "月榜":
          return "monthRing";
        default:
          return "weekRing";
      }
    },
    async chooseRankType(type) {
      const _type = this.getRankParam(type);
      this.defaultRankCycle = type;
      await this.getDeptStatus(_type);
      this.dealRankData();
    },
    async getDeptStatus(type) {
      try {
        // console.log("----", type);
        this.showLoad = true;
        let _type = type || this.getRankParam();
        const _url = `/services/gttt-wxstats-mgt/activity/${_type}`;

        const ret = await wxConPost(_url);

        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;

        let deptData = _.get(ret, ["data", "data"], []);
        let timeArea = _.get(ret, ["data", "timeArea"], "");
        this.dataAllList = deptData;

        this.dealDateData(timeArea);
      } catch (error) {
        console.log("----", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },

    // 处理人员数据
    dealRankData() {
      const curDataObj = _.groupBy(this.dataAllList, "id");
      let curData = curDataObj[this.activityId];
      if (curData) {
        // console.log("----", curDataObj, curData, this.activityId);
        //  count: 1
        // deptId: 3386
        // deptName: "肇庆车务段"
        // id: "161706463911809745ff2"
        // paperScore: 10
        // score: 6
        // timeMs: 50631
        let _tmpdataList = [];
        for (const iterator of curData) {
          if (iterator.deptName) {
            // 正确率
            let correctRate = 0;
            if (iterator.paperScore) {
              correctRate = (iterator.score / iterator.paperScore) * 100;
            }

            _tmpdataList.push(
              Object.assign(iterator, {
                correctRate,
                // 正确率: correctRate.toFixed(2),
              })
            );
          }
        }
        curData = _.reverse(_.sortBy(_tmpdataList, ["correctRate"]));

        let dataList = [];
        for (const [index, iterator] of curData.entries()) {
          if (iterator.deptName) {
            dataList.push(
              Object.assign(iterator, {
                正确率排名: index + 1,
              })
            );
          }
        }

        this.dataList = _.reverse(_.sortBy(dataList, ["score"]));
        this.initRankGroup(this.curTabItem);

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
    //  "2022-03-21_2022-03-23"
    dealDateData(timeArea) {
      this.startDate = timeArea.slice(0, 10);
      this.endDate = timeArea.slice(11, 21);
    },

    // 数据排序
    sortData(data) {
      console.log("data----", data);
      if (data && data.value) {
        if (data.order == "aes") {
          // 升序
          this.showList = _.sortBy(this.showList, [data.value]);
        } else {
          // 降序
          this.showList = _.reverse(_.sortBy(this.showList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
  },
  async created() {
    this.getDeptStatus();
  },
};
</script>

<style scoped></style>

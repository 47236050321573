<template>
  <div style="text-align: left; margin: 10px 10px">
    <Select
      v-model="modelActivity"
      style="width: 200px"
      @on-change="chooseItem"
    >
      <Option v-for="item in dataList" :value="item.id" :key="item.id">{{
        item.name
      }}</Option>
    </Select>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import { wxConPost } from "../api/httpApi";

export default {
  name: "leitaiActivity",
  components: {},

  data() {
    return {
      dataList: [],
      modelActivity: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    async getDeptStatus() {
      try {
        const ret = await wxConPost(
          `/services/gttt-answer-exam/activity/getList`
        );
        this.dataList = ret.ret;
        this.modelActivity = this.dataList[0] ? this.dataList[0].id : "";
        this.chooseItem();
      } catch (error) {}
    },
    chooseItem(id) {
      this.$emit("chooseActivity", this.modelActivity);
    },
  },
  async created() {
    this.getDeptStatus();
  },
};
</script>

<style scoped>
</style>
